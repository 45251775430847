/* input {
    border-radius: 0 !important;
}
.label-font {
    color: #949494 !important;
    font-size: 14px !important;
}

.btn-click, .btn-click:focus, .btn-click:hover  {
    border: 1px solid #2B9191 !important;
    border-color: #2B9191 !important;
    box-shadow: inset 0 1px 0 rgba(43, 145, 145, 0.15),0 1px 1px rgba(43, 145, 145, 0.075) !important;
    
}

.btn-click-cancel, .btn-click-cancel:focus, .btn-click-cancel:hover  {
    border: 1px solid #858585 !important;
    border-color: #858585 !important;
    box-shadow: inset 0 1px 0 rgba(43, 145, 145, 0.15),0 1px 1px rgba(43, 145, 145, 0.075) !important;
    
}*/

.confirm-modal-shadow .modal-content {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.primary-bg-color-sp-custom {
  background-color: #2b9191 !important; /* Set initial background color */
}

.primary-bg-color-sp-custom:hover {
  background-color: #287e7e !important; /* Darker background color on hover */

}