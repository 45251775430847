/* * {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
}

.main-body {
  width: 400px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
}

 */



.login-bg {
  background-image: url("../../../public/assets/img/login-bg.png") !important;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.center-Item {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  /* height: 800px; */
  height: 90vh;
}
.login-card {
  /* width: calc(100% - 200px);
  padding: 25px 15px;
  text-align: center;
  height: calc(100vh - 400px); */
  width: 100%;
  padding: 25px 15px;
  text-align: center;
  height: auto;
}

.icon-input {
  position: absolute;
  top: 10px;
  left: 15px;
  color: #2b9191 !important;
}

/* .email-signInOut input[type="email"] {
  padding-left: 35px !important;
} */

input[type="password"] {
  padding-left: 35px !important;
}

input[type="email"]::placeholder,
input[type="password"]::placeholder {
  padding-left: 5px;
}

input[type="checkbox"]:checked {
  background-color: #2b9191 !important;
  border-color: #2b9191 !important;
}

@media screen and (max-width: 480px) {
  .center-Item {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 480px;
  }
  .login-card {
    /* width: calc(100% - 200px);
    padding: 25px 15px;
    text-align: center;
    height: calc(100vh - 400px); */
    width: 100%;
    padding: 25px 15px;
    text-align: center;
    margin-bottom: 5rem;
    font-size: 11px;
  }
}
