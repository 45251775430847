* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  /* display: flex;
  align-items: flex-start;
  justify-content: space-between; */
}

.container {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 110px;
  padding-right: 50px;
  /* border: 1px solid red; */
}

.inner-top {
  display: flex;
  justify-content: space-between;
  width: 102%;
  height: 100%;
  padding-top: 30px;
  /* border: 1px solid red; */
}

.logo-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  font-weight: 300;
  /* border: 1px solid blue; */
}

.profile-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid red; */
}

.secondary-header {
  color: #494949;
  font-size: 24px;
  font-weight: 300;
  /* border: 1px solid red; */
}

.log-out{
  font-family: Roboto, Rubik, sans-serif;
  color: teal !important;
  text-decoration: none;
  cursor: pointer;
}