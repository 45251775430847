* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.header {
    display: flex;
    justify-content: center;
    position: relative;
    font-weight: bold;
}

.header span {
    color: #888;
    position: absolute;
    right: 0;
}