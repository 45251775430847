/* create reset css class */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.dashboard {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  padding: 1rem;
  height: 100vh;
  background-color: #f4f4f4;
  margin-top: 1.5rem;
}

.dashboard__small {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.dashboard__small h1 {
  align-self: baseline;
  color: gray;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.dashboard__small h2 {
  align-self: center;
  font-size: 3rem;
  margin-bottom: 1rem;
}

.dashboard__small p {
  align-self: baseline;
  color: gray;
  font-size: 0.9rem;
  margin-bottom: 0;
}

.dashboard__calendar {
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  grid-column: span 3;
  height: 100%;
  padding: 1rem;
}

.dashboard__calendar h1 {
  align-self: baseline;
  color: gray;
  font-size: 1rem;
  margin-bottom: 1rem;
}
.dashboard__pieChart {
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  grid-column: span 2;
  height: 100%;
  padding: 1rem;
}

.dashboard__pieChart h1 {
  align-self: baseline;
  color: gray;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.dashboard__clients {
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  grid-row: span 1;
  height: 100%;
  justify-content: start;
  padding: 1rem;
}

.dashboard__clients h1 {
  align-self: baseline;
  color: gray;
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}

.dashboard__clients div {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-around;
  height: 100%;
}

.dashboard__clients p {
  font-size: 1.15rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.dashboard__smallTable {
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  height: 100%;
  padding: 2rem;
}

.dashboard__lineChart {
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  height: 100%;
  padding: 2rem;
  grid-column: span 2;
  margin-bottom: 1rem;
}

.dashboard__lineChart p {
  align-self: baseline;
  color: gray;
  font-size: 0.9rem;
}

.dashboard__treeMap {
  align-items: baseline;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: start;
  padding: 1rem;
  grid-column: span 2;
}

.dashboard__table {
  align-items: baseline;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: start;
  padding: 1rem;
  grid-column: span 4;
}

.dashboard__item {
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.dashboard__item--1 {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
}

.dashboard__item--2 {
  grid-column: 3 / 4;
  grid-row: 1 / 2;
}

.dashboard__item--3 {
  grid-column: 3 / 4;
  grid-row: 2 / 3;
}

.dashboard__item--4 {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}
