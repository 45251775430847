.modalBody {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next line */
  /* margin-bottom: 10px; */
  align-items: center; /* Align items vertically */
  /* center items within their columns */
  justify-content: center;
}

.modalBody > * {
  flex: 1; /* Each child element takes equal width */
  margin-right: 20px; /* Space between h4 and p */
}

.modalBody h4 {
  order: 1; /* Order the h4 to appear first */
}

.modalBody p {
  order: 2; /* Order the p to appear second */
}

.modalBody span {
  font-weight: bold;
  margin-bottom: 1rem;
}

.modalBody p:nth-child(2) {
  /* text-wrap: nowrap;
    align-self: flex-start; */
}

.modalBody:nth-child(even) {
  background-color: #f2f2f2; /* Light gray background for even rows */
}
