* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-right: 120px;
    padding-left: 20px;
    margin-left: 145px;
    max-width: fit-content;
  }
  
  .table-header {
    display: flex;
    /* align-self: flex-start; */
    align-items: baseline;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 18px;
    font-weight: 300;
    width: 100%;
    color: var(--light-blue);
    /* border: 1px solid red; */
  }
  
  .status,
  .status-pending,
  .status-paid,
  .status-unpaid {
    border-radius: 0.2rem;
    padding: 0.5rem 1rem;
    text-align: center;
  }
  
  .status {
    border-radius: 0.2rem;
    background-color: #e2445c;
    padding: 0.5rem 1rem;
    text-align: center;
  }
  
  .status-pending {
    background-color: #ffcb00;
  }
  
  .status-paid {
    background-color: #00c875;
  }
  
  .status-unpaid {
    background-color: #e2445c;
  }
  .heading {
    color: #1d8989;
    font-size: 50px;
    font-weight: 400;
    border-bottom: 0px solid #b2b2b2;
  }
  
  .icon-input {
    position: absolute;
    width: 16px;
    left: 10px;
    top: 10px;
    fill: #515151 !important;
  }
  .paddingLeft {
    padding-left: 35px !important;
  }
  
  .table-design {
   
    text-align: center !important;
    font-family:'Segoe UI' !important;
    size: 14px !important;
  }
  .statusbgcolor{
    background-color: #FDAB3D !important;
    color:white !important;
  }
  .statusbgBilled{
    background-color: #00C875 !important;
    color:white !important;
  }

  table{
    width: 100%;
  }
  table > thead > tr > th,
  table > tbody > tr > td{
    padding: 0.5rem 0.5rem;
  }