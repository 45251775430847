/* * {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-right: 120px;
  padding-left: 20px;
  margin-left: 145px;
  max-width: fit-content;
}

.table-header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 18px;
  font-weight: 300;
  width: 100%;
  color: var(--light-blue);
}

.status,
.status-pending,
.status-paid,
.status-unpaid {
  border-radius: 0.2rem;
  padding: 0.5rem 1rem;
  text-align: center;
}

.status {
  border-radius: 0.2rem;
  background-color: #e2445c;
  padding: 0.5rem 1rem;
  text-align: center;
}

.status-pending {
  background-color: #ffcb00;
}

.status-paid {
  background-color: #00c875;
}

.status-unpaid {
  background-color: #e2445c;
} */

tr:nth-child(even) {
  background: white !important;
}
tr:nth-child(odd) {
  background: white !important;
}
tr:nth-child(1) {
  background: none;
}

.heading {
  color: #1d8989;
  font-size: 50px;
  font-weight: 400;
  border-bottom: 1px solid #b2b2b2;
}

.action-btn {
  width: 14px;
  height: auto;
  /* padding-left: 5px !important; */
}

.action-btn-edit {
  fill: #ffc107 !important;
}
.action-btn-delete {
  fill: #dc3545 !important;
}

.icon-input {
  position: absolute;
  width: 16px;
  left: 10px;
  top: 10px;
  fill: #515151 !important;
}
.paddingLeft {
  padding-left: 35px !important;
}

.sampleBtn {
  position: absolute;
  width: 16px;
  right: 30px;
  top: 8px;
  fill: #2B9191 !important;
}

.table-border {
  border-left: 5px solid#1d8989;
}
.statusfield1 {
  background-color: #fdab3d !important;
  color: azure !important;
}
.statusfield2 {
  background-color: #00c875 !important;
  color: azure !important;
}
.statusfield3 {
  background-color: #3d50fd !important;
  color: azure !important;
}
.statusfield4 {
  background-color: #e2445c !important;
  color: azure !important;
}
.buttonleft {
  float: left !important;
  width: 62.375 !important;
  height: 62.375 !important;
  margin-top: 80px;
}
.buttnstyle {
  width: 62.375 !important;
  height: 62.375 !important;
}
.commentmargin {
  margin-left: 15px !important;
}
.positionLeft {
  position: absolute;
  left: 4px;
}
.positionRight {
  position: absolute;
  right: 4px;
}

.sortBtn:hover {
  background-color: #2b91916b;
  color: white;

}
.ActiveSortBtn {
  background-color: #2B9191;
  color: white;
}

select.form-select option {
  background-color: white !important;
  color: black !important;
}