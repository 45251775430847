:root {
  --triangle-position: 15%;
  --sidebar-width: 56px;
  --icon-dimensions: 40px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-family);
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100%;
  background-color: #f6f7fb;
  border: 1px solid lightgray;
}

.sliderOut {
  position: absolute;
  top: 0;
  left: var(--sidebar-width);
  width: 100px;
  height: 100%;
  background-color: #f6f7fb;
  border: 1px solid lightgray;
  box-shadow: 0 5px 10px lightgray;
  /* z-index: 1; */
}

.slider-content-container {
  position: relative;
  visibility: hidden;
}

.slider-content {
  position: absolute;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 5rem;
  top: 12rem;
  overflow: hidden;
  /* border: 1px solid red; */
}

.slider-content ul {
  padding: 12px;
  justify-content: center;
  list-style: none;
}

.slider-content ul li {
  padding: 5px;
  font-size: 14px;

  /* border: 1px solid red; */
}

.slider-content ul li a {
  color: #323338;
  text-decoration: none;
}

.slider-content ul li:hover {
  background-color: lightgray;
  border-radius: 6px;
}
.sidebar {
  /* position: absolute; */
  position: fixed;
  top: 0;
  left: 0;
  background-color: #2b9191;
  width: var(--sidebar-width);
  height: 100%;
  /* border: 1px solid red; */
}

.triangle {
  /* background-color: #f6f7fb; */
  background-color: #ffffff;
  position: absolute;
  height: 30px;
  width: 15px;
  left: 48px;
  /* top: var(--triangle-position); */
  margin-top: calc(15px / 2);
  text-align: right;
  align-content: center;
  clip-path: polygon(100% 0%, 0% 50%, 100% 100%);
  z-index: 1;
  /* border: 1px solid red; */
}

.icon-container-container {
  position: relative;
}

.icon-container {
  position: absolute;
  left: 10;
  /* top: 15%; */
  top: 230px;
  height: calc(var(--icon-dimensions) * 7);
  width: 100%;
  /* border: 1px solid yellow; */
}

.home,
.person,
.employees,
.billing,
.reporting,
.screnout,
.helpus,
.contacts,
.dashboard,
.projectSample {
  position: absolute;
  background-image: url(./Project.svg.svg);
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
  left: 50%;
  transform: translate(-50%);
  width: var(--icon-dimensions);
  height: var(--icon-dimensions);
}

.person {
  background-image: url(./clients.svg);
  margin-top: var(--icon-dimensions);
}

.contacts {
  background-image: url(./contacts.svg);
  margin-top: calc(var(--icon-dimensions) * 2);
}
/* .employees {
  background-image: url(./Employees.svg);
  margin-top: calc(var(--icon-dimensions) * 3);
} */
.billing {
  background-image: url(./Billing.svg);
  margin-top: calc(var(--icon-dimensions) * 3);
}
.projectSample {
  background-image: url(./sampleProject.svg);
  margin-top: calc(var(--icon-dimensions) * 4);
}
.reporting {
  background-image: url(./Reporting.svg);
  margin-top: calc(var(--icon-dimensions) * 5);
}
.dashboard {
  background-image: url(./Dashboard.svg);
  margin-top: calc(var(--icon-dimensions) * 5);
}
/*.helpus {
  background-image: url(./ScreenOut.svg);
  margin-top: calc(var(--icon-dimensions) * 14);
} */

.home:hover,
.person:hover,
.employees:hover,
.billing:hover,
.reporting:hover,
.projectSample:hover,
.screnout:hover,
.helpus:hover,
.contacts:hover,
.dashboard:hover {
  background-color: #10131e;
  border-radius: 6px;
}

.home:isactive,
.person:isactive,
.employees:isactive,
.billing:isactive,
.reporting:isactive,
.projectSample:isactive,
.screnout:isactive,
.helpus:isactive,
.contacts:isactive,
.dashboard:isactive {
  background-color: #696d81;
  border-radius: 6px;
}
