* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* table {
    flex-direction: column;
    display: flex;
    width: 100%;
    border-collapse: collapse;
    align-items: center;
    
}  */

/* table, thead, th {
    border-bottom: 1px solid black;
} */



thead, tr {
    border-bottom: 1px solid black;
}



.row {
    border-bottom: 1px solid lightgray;
    text-wrap: nowrap
}

.total {
    font-weight: bold;
}

