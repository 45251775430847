.justify-content-center {
  justify-content: center !important;
}

nav {
  font-weight: 400;
  width: fit-content;
}

.pagination {
  display: flex;
  justify-content: end;
  padding-left: 0;
  list-style: none;
  font-size: 14px;
  
}

.page-link {
  position: relative;
  display: block;
  color: #2B9191 !important;
  text-decoration: none;
  /* background-color: #fff; */

  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #2B9191;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #2B9191;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(43, 145, 145, 0.25) !important;
  /* box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); */
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  /* color: #fff; */
  color: white !important;
  /* background-color: #0d6efd; */
  background-color: #2B9191;
  border-color: #2B9191;
  z-index: -1;
}
.page-item.disabled .page-link {
  color: #323338; /*Disabled Text Color */
  pointer-events: none;
  background-color: #fff;
  /* border-color: #dee2e6; */
  border-color: #c3c6d4;
}

.page-link {
  /* padding: 0.375rem 0.75rem; */
  padding: 4px 8px;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
