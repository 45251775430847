@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&family=Rubik:wght@300;400&display=swap');

:root {
  --font-family: Roboto, Rubik, sans-serif !important;
  --light-blue: #579bfc;
  --light-purple: #a25ddc;
  --status-pending-background-color: #ffcb00;
  --status-paid-background-color: #00c875;
  --status-unpaid-background-color: #e2445c;
  --status-color-orange: #ff9900cc;
}

body {
  font-family: var(--font-family);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: var(--font-family);
}

nav {
  z-index: 1 !important;
}

input:focus,
textarea:focus,
select:focus {
  border-color: rgb(43 145 145 / 25%) !important;
  box-shadow: 0 0 0 0.25rem rgb(43 145 145 / 25%) !important;
}

.bg-color-green {
  background-color: var(--status-paid-background-color) !important;
}

.bg-color-pending {
  background-color: var(--status-pending-background-color) !important;
}

.bg-color-orange {
  background-color: var(--status-color-orange) !important;
}

.bg-color-danger {
  background-color: var(--status-unpaid-background-color) !important;
}

.bg-color-purple {
  background-color: var(--light-purple) !important;
}

.bg-color-billed {
  background-color: var(--light-blue) !important;
}

.color-white {
  color: white !important;
}

.color-green {
  color: var(--status-paid-background-color) !important;
}

.color-pending {
  color: var(--status-pending-background-color) !important;
}

.color-danger {
  color: var(--status-unpaid-background-color) !important;
}

.helpUs {
  position: fixed;
  bottom: 0;
  right: 0;
}



.primary-color-sp {
  color: #2b9191 !important;
}

.secondary-color-sp {
  color: #515151 !important;
}

.primary-bg-color-sp {
  background-color: #2b9191 !important;
}


.primary-bg-color-sp:hover {
  background-color: #227474 !important;
  opacity: 0.8;
}

.primary-button-color-sp-outline {
border: 1px solid #2b9191 !important;
}

.primary-bg-color-sp-ouline {
  border: 1px solid #2b9191 !important;
  color: #2b9191 !important;
}
.secondary-bg-color-sp {
  background-color: #515151 !important;
}

.width-90vw {
  width: 90vw !important;
  max-width: 90vw !important;
}
.ShortButtonTH {
  color: #2b919152;
}
.ShortButtonTH:hover {
  cursor: pointer;
  color: #2b9191;
}
