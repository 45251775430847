
.businessClassTable {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
user-select: none;
-webkit-user-select: none;
}

/* give the table a border */
.businessClassTable th,
.businessClassTable td {
  border: 1px solid #ddd;
  padding: 8px;
}

/* give the table a header */
.businessClassTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #f2f2f2;
  color: black;
}

.businessClassTable th:nth-child(1) {
  text-align: left;
}

.businessClassTable .td {
    text-align: center;
    cursor: pointer;
}



/* give the table a hover effect */
.businessClassTable .td:hover {
  background-color: gray !important;
}

/* give the table a striped effect */
.businessClassTable tr:nth-child(even) {
  background-color: #f2f2f2 !important;
}

.not-selectable[innerText='-'] {
  user-select: none;
  -webkit-user-select: none;
}






